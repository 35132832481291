import { Button, Col, Form, Row, Table } from "antd";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import {
  deleteLinks,
  getLinks,
  patchLinks,
  postLinks,
} from "../../host/Config";
import { MdDelete } from "react-icons/md";
import { TbEdit } from "react-icons/tb";
import { BsEyeFill } from "react-icons/bs";
import { message } from "antd";
import { useStore } from "../../store/Store";
import DescriptionModal from "../../utils/DescriptionModal";
import dayjs from "dayjs";
import { InputDesc, InputText } from "../../utils/Inputs";

export default function Links() {
  const [form] = Form.useForm();
  const [data, setData] = useState(null);
  const [sel_data, setSel_data] = useState(null);
  const [modal, setModal] = useState(false);
  const [edit, setedit] = useState(null);
  const [changePage, setchangePage] = useState(false);
  const [cookie, _] = useCookies();

  const setLoader = useStore((state) => state.setLoader);

  const editData = (res) => {
    setchangePage(true);
    res.date = dayjs(res.date, "YYYY-MM-DD");
    res.time = dayjs(res.time, "HH:mm");
    form.setFieldsValue(res);
    setedit(res.id);
  };

  const showModal = () => {
    setchangePage(true);
  };

  const columns = [
    {
      title: "#",
      key: "#",
      render: (text, res, index) => {
        return index + 1;
      },
    },
    {
      title: "Nom",
      key: "title",
      dataIndex: "title",
      
    },
    {
      title: "Havola",
      key: "link",
      dataIndex: "link",
      width: "200px",
      render:(res)=>{
        return (
          <a href={res} target="_blank">Havolaga o'tish</a>
        )
      }
    },
    {
      title: "Matn",
      key: "description",
      dataIndex: "",
      render: (res) => {
        return (
          <div className="table_btns">
            <Button
              type="primary"
              onClick={() => {
                setModal(true);
                setSel_data(res);
              }}
            >
              <BsEyeFill size={"1.3em"} />
            </Button>
          </div>
        );
      },
    },
    {
      title: "O'zgartirish",
      key: "edit",
      dataIndex: "id",
      render: (res, objc) => {
        return (
          <Button
            onClick={() => {
              editData(objc);
            }}
            type="primary"
          >
            <TbEdit size={"1.3em"} />
          </Button>
        );
      },
    },
    {
      title: "O'chirish",
      key: "delete",
      dataIndex: "id",
      render: (res) => {
        return (
          <Button
            type="primary"
            onClick={() => {
              deleteData(res);
            }}
            danger
          >
            <MdDelete size={"1.3em"} />
          </Button>
        );
      },
    },
  ];

  const getData = () => {
    getLinks(cookie.token).then((res) => {
      setData(res.data);
      setLoader(false);
    });
  };

  const deleteData = (id) => {
    setLoader(true);
    deleteLinks(cookie.token, id)
      .then((res) => {
        message.success("Ma'lumot o'chirildi");
        getData();
      })
      .catch((err) => {
        setLoader(false);
        message.error("Ma'lumot o'chirilmadi");
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const onFinish = (event) => {
    setLoader(true);

    if (edit === null) {
      postLinks(cookie.token, event)
        .then((res) => {
          message.success("Ma'lumot saqlandi");
          handleClose();
        })
        .catch((err) => {
          setLoader(false);
          message.error("Ma'lumot saqlanmadi");
        });
    } else {
      patchLinks(cookie.token, event, edit)
        .then((res) => {
          message.success("Ma'lumot o'zgartirildi");
          handleClose();
        })
        .catch((err) => {
          setLoader(false);
          message.error("Ma'lumot o'zgartirilmadi");
        });
    }
  };

  const handleClose = () => {
    setchangePage(false);
    setedit(null);
    getData();
    form.resetFields();
  };

  return (
    <div>
      <div className="adminBox">
        {!changePage ? (
          <>
            <div className="admin_btns">
              <Button
                style={{ marginBottom: "20px" }}
                onClick={showModal}
                type="primary"
              >
                Havola qo'shish
              </Button>
            </div>
            <Table
              rowKey={(res) => res.id}
              dataSource={data}
              columns={columns}
            />
          </>
        ) : (
          <>
            <Form
              form={form}
              name="basic"
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <div className="admin_btns">
                <Button
                  style={{ marginBottom: "20px" }}
                  onClick={handleClose}
                  type="primary"
                >
                  Orqaga
                </Button>
                <Button
                  style={{ marginLeft: "20px", backgroundColor: "#00a900" }}
                  type="primary"
                  htmlType="submit"
                >
                  Saqlash
                </Button>
              </div>
              <Row style={{ paddingTop: "20px" }}>
                <Col style={{ padding: "0px 20px" }} lg={9} md={24}>
                  <InputText label="Nom" name="title" />
                </Col>
                <Col style={{ padding: "0px 20px" }} lg={15} md={24}>
                  <InputText label="Havola" name="link" />
                </Col>
              </Row>
              <div style={{ padding: "15px 20px 60px 20px" }}>
                <InputDesc label="Izoh" name="description" />
              </div>
            </Form>
          </>
        )}
      </div>
      {sel_data && (
        <DescriptionModal
          title={sel_data.title}
          open={modal}
          onOk={() => {
            setModal(false);
          }}
          onCancel={() => {
            setModal(false);
          }}
          desc={sel_data.description}  
        />
      )}
    </div>
  );
}
