import { FiLink } from "react-icons/fi";
import { FaFileAudio, FaUsers, FaWpforms } from "react-icons/fa";
import { AiOutlineFileUnknown, AiOutlineVideoCameraAdd } from "react-icons/ai";
import { SiGoogleclassroom } from "react-icons/si";
import { GrDocumentConfig, GrDocumentPerformance, GrDocumentText } from "react-icons/gr";
import { MdOutlineEventAvailable, MdPerson, MdTopic, MdNewspaper } from "react-icons/md";
import { BsFiletypePptx, BsInfoCircle, BsListTask } from "react-icons/bs";
import { RiQuestionnaireFill } from "react-icons/ri";

const menuLinks = {
    default: "1",
    articles: "2",
    lessons: "8",
    events: "9",
    links: "11",
    audio: "13",
    videos: "12",
    docs: "14",
    "docs-categories": "17",
    class: "18",
    news: "15",
    presentations: "16",
    dictionaries: "22",
    "site-info": "23",
    questions: "19",
    students: "20",
    tests: "21",
    results: "24",
};

const menuItems = [
    {
        key: 2,
        path: "articles",
        name: "Maqolalar",
        icon: MdPerson,
    },
    {
        key: 8,
        path: "lessons",
        name: "Mavzular",
        icon: MdTopic,
    },
    {
        key: 9,
        path: "events",
        name: "Tadbirlar",
        icon: MdOutlineEventAvailable,
    },
    {
        key: 11,
        path: "links",
        name: "Havolalar",
        icon: FiLink,
    },
    {
        key: 12,
        path: "videos",
        name: "Video lavhalar",
        icon: AiOutlineVideoCameraAdd,
    },
    {
        key: 13,
        path: "audio",
        name: "Ovozli xabar",
        icon: FaFileAudio,
    },
    {
        key: 14,
        path: "docs",
        name: "Hujjatlar",
        icon: GrDocumentText,
    },
    {
        key: 15,
        path: "news",
        name: "Yangiliklar",
        icon: MdNewspaper,
    },
    {
        key: 16,
        path: "presentations",
        name: "Prezentatsiyalar",
        icon: BsFiletypePptx,
    },
    {
        key: 17,
        path: "docs-categories",
        name: "Hujjat turlar",
        icon: GrDocumentConfig,
    },
    {
        key: 18,
        path: "class",
        name: "Sinflar",
        icon: SiGoogleclassroom,
    },
    {
        key: 19,
        path: "questions",
        name: "Savollar",
        icon: RiQuestionnaireFill,
    },
    {
        key: 20,
        path: "students",
        name: "O'quvchilar",
        icon: FaUsers,
    },
    {
        key: 21,
        path: "tests",
        name: "Testlar",
        icon: AiOutlineFileUnknown,
    },
    {
        key: 22,
        path: "dictionaries",
        name: "Lug'atlar",
        icon: BsListTask,
    },
    {
        key: 23,
        path: "site-info",
        name: "Sayt ma'lumotlari",
        icon: BsInfoCircle,
    },
    {
        key: 24,
        path: "results",
        name: "Natijalar",
        icon: GrDocumentPerformance,
    },
    {
        key: 25,
        path: "form-requests",
        name: "Xabarlar",
        icon: FaWpforms,
    },
];

export { menuLinks, menuItems };
