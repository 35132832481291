import { Button, Col, Form, Image, Modal, Row, Table } from "antd";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import {
  deleteEvents,
  getEvents,
  patchEvents,
  postEvents,
} from "../../host/Config";
import { MdDelete } from "react-icons/md";
import { TbEdit } from "react-icons/tb";
import { FiMapPin } from "react-icons/fi";
import { BsEyeFill } from "react-icons/bs";
import { message } from "antd";
import { useStore } from "../../store/Store";
import { getBase64 } from "../../components/Objects";
import DescriptionModal from "../../utils/DescriptionModal";
import YandexMap from "../../utils/YandexMap";
import dayjs from "dayjs";
import {
  InputDate,
  InputDesc,
  InputImg,
  InputText,
  InputTime,
} from "../../utils/Inputs";

export default function Events() {
  const [form] = Form.useForm();
  const [data, setData] = useState(null);
  const [sel_data, setSel_data] = useState(null);
  const [image_id, setimage_id] = useState(null);
  const [modal, setModal] = useState(false);
  const [edit, setedit] = useState(null);
  const [changePage, setchangePage] = useState(false);
  const [btnDis, setBtnDis] = useState(false);
  const [cookie, _] = useCookies();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [map, setMap] = useState(false);
  const [coords, setCoords] = useState([41.311081, 69.240562]);

  const setLoader = useStore((state) => state.setLoader);

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setBtnDis(true);
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setBtnDis(false);
      setimage_id(info.file.originFileObj);
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };

  const editData = (res) => {
    setchangePage(true);
    res.date = dayjs(res.date, "YYYY-MM-DD");
    res.time = dayjs(res.time, "HH:mm");
    form.setFieldsValue(res);
    setImageUrl(res.image);
    setedit(res.id);
  };

  const showModal = () => {
    setchangePage(true);
  };

  const columns = [
    {
      title: "#",
      key: "#",
      render: (text, res, index) => {
        return index + 1;
      },
    },
    {
      title: "Nomi",
      key: "title",
      dataIndex: "title",
    },
    {
      title: "Sanasi",
      key: "date",
      dataIndex: "date",
    },
    {
      title: "Vaqti",
      key: "time",
      dataIndex: "time",
    },
    {
      title: "Rasmi",
      key: "image",
      dataIndex: "image",
      render: (value) => {
        return <Image src={value} width="100px" />;
      },
    },
    {
      title: "Manzili",
      key: "address",
      dataIndex: "address",
    },
    {
      title: "Manzili",
      key: "location",
      dataIndex: "id",
      render: (id, res) => {
        return res.location && res.location.length !== 0 ? (
          <div className="table_btns">
            <Button
              type="primary"
              onClick={() => {
                setMap(true);
                setCoords(JSON.parse("[" + res.location[0] + "]"));
              }}
            >
              <FiMapPin size={"1.3em"} />
            </Button>
          </div>
        ) : (
          ""
        );
      },
    },
    {
      title: "Matn",
      key: "description",
      dataIndex: "id",
      render: (id, res) => {
        return (
          <div className="table_btns">
            <Button
              type="primary"
              onClick={() => {
                setModal(true);
                setSel_data(res);
              }}
            >
              <BsEyeFill size={"1.3em"} />
            </Button>
          </div>
        );
      },
    },
    {
      title: "O'zgartirish",
      key: "edit",
      dataIndex: "id",
      render: (res, objc) => {
        return (
          <Button
            onClick={() => {
              editData(objc);
            }}
            type="primary"
          >
            <TbEdit size={"1.3em"} />
          </Button>
        );
      },
    },
    {
      title: "O'chirish",
      key: "delete",
      dataIndex: "id",
      render: (res) => {
        return (
          <Button
            type="primary"
            onClick={() => {
              deleteData(res);
            }}
            danger
          >
            <MdDelete size={"1.3em"} />
          </Button>
        );
      },
    },
  ];

  const getEventsData = () => {
    getEvents(cookie.token).then((res) => {
      setData(res.data);
      setLoader(false);
    });
  };

  const deleteData = (id) => {
    setLoader(true);
    deleteEvents(cookie.token, id)
      .then((res) => {
        message.success("Ma'lumot o'chirildi");
        getEventsData();
      })
      .catch((err) => {
        setLoader(false);
        message.error("Ma'lumot o'chirilmadi");
      });
  };

  useEffect(() => {
    getEventsData();
  }, []);

  const onFinish = (event) => {
    setLoader(true);
    event.image = image_id;
    event.location = `${coords[0]},${coords[1]}`;
    let date = event.date;
    let time = event.time;
    event.date = `${date.$y}-${
      date.$M > 8 ? date.$M + 1 : "0" + (date.$M + 1)
    }-${date.$D}`;
    event.time = `${time.$H}:${time.$m > 9 ? time.$m : "0" + time.$m}`;

    let formData = new FormData();
    formData.append("address", event.address);
    formData.append("date", event.date);
    formData.append("description", event.description);
    formData.append("location", event.location);
    formData.append("time", event.time);
    formData.append("title", event.title);

    if (edit === null) {
      formData.append("image", event.image);
      postEvents(cookie.token, formData)
        .then((res) => {
          message.success("Ma'lumot saqlandi");
          handleClose();
        })
        .catch((err) => {
          setLoader(false);
          message.error("Ma'lumot saqlanmadi");
        });
    } else {
      if (image_id) {
        formData.append("image", event.image);
      }
      patchEvents(cookie.token, formData, edit)
        .then((res) => {
          message.success("Ma'lumot o'zgartirildi");
          handleClose();
        })
        .catch((err) => {
          setLoader(false);
          message.error("Ma'lumot o'zgartirilmadi");
        });
    }
  };

  const handleClose = () => {
    setimage_id(null);
    setImageUrl();
    setchangePage(false);
    setedit(null);
    setCoords([41.311081, 69.240562]);
    getEventsData();
    form.resetFields();
  };

  const handleMapClick = (e) => {
    const coords = e.get("coords");
    setCoords(coords);
  };

  return (
    <div>
      <div className="adminBox">
        {!changePage ? (
          <>
            <div className="admin_btns">
              <Button
                style={{ marginBottom: "20px" }}
                onClick={showModal}
                type="primary"
              >
                Tadbir qo'shish
              </Button>
            </div>
            <Table
              rowKey={(res) => res.id}
              dataSource={data}
              columns={columns}
            />
          </>
        ) : (
          <>
            <Form
              form={form}
              name="basic"
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <div className="admin_btns">
                <Button
                  style={{ marginBottom: "20px" }}
                  onClick={handleClose}
                  type="primary"
                >
                  Orqaga
                </Button>
                <Button
                  disabled={btnDis}
                  style={{ marginLeft: "20px", backgroundColor: "#00a900" }}
                  type="primary"
                  htmlType="submit"
                >
                  Saqlash
                </Button>
              </div>
              <Row style={{ paddingTop: "20px" }}>
                <Col style={{ padding: "0px 20px" }} lg={12} md={24}>
                  <InputText label="Nom" name="title" />
                </Col>
                <Col style={{ padding: "0px 20px" }} lg={12} md={24}>
                  <InputDate label="Sana" name="date" />
                </Col>
                <Col style={{ padding: "0px 20px" }} lg={12} md={24}>
                  <InputTime label="Vaqt" name="time" />
                </Col>
                <Col style={{ padding: "0px 20px" }} lg={12} md={24}>
                  <InputImg
                    label="Rasm"
                    name="image"
                    imageUrl={imageUrl}
                    handleChange={handleChange}
                    loading={loading}
                  />
                </Col>
                <Col style={{ padding: "0px 20px" }} lg={24} md={24}>
                  <InputText label="Manzili" name="address" />
                </Col>
              </Row>
              <div style={{ padding: "15px 20px 60px 20px" }}>
                <InputDesc label="Izoh" name="description" />
              </div>
              <Col style={{ padding: "0px 20px" }} lg={24} md={24}>
                <YandexMap coords={coords} handleMapClick={handleMapClick} />
              </Col>
            </Form>
          </>
        )}
      </div>
      {sel_data && (
        <DescriptionModal
          title={sel_data.title}
          open={modal}
          onOk={() => {
            setModal(false);
          }}
          onCancel={() => {
            setModal(false);
          }}
          desc={sel_data.description}
        />
      )}
      {map && (
        <Modal
          open={map}
          footer={false}
          onCancel={() => {
            setMap(false);
            setCoords([41.311081, 69.240562]);
          }}
        >
          <div style={{ margin: 20 }}>
            <YandexMap coords={coords} />
          </div>
        </Modal>
      )}
    </div>
  );
}
