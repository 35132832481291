import { Button, DatePicker, Form, Input, InputNumber, Select, TimePicker, Upload } from "antd";
import { useState } from "react";
import ReactQuill from "react-quill";
import { beforeUpload, beforeUploadAudio, beforeUploadFile } from "../components/Objects";
import { PlusOutlined, LoadingOutlined, UploadOutlined, EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";

const FormItem = ({ label, name, required = true, children }) => {
    return required ? (
        <Form.Item
            label={label}
            name={name}
            rules={[
                {
                    required: true,
                    message: "Bu maydonni to'ldirish shart",
                },
            ]}
        >
            {children}
        </Form.Item>
    ) : (
        <Form.Item label={label} name={name}>
            {children}
        </Form.Item>
    );
};

const InputNum = ({ name, label, required = true, disabled = false, placeholder = "" }) => {
    return (
        <FormItem label={label} name={name} required={required}>
            <InputNumber
                min={0}
                name={name}
                disabled={disabled}
                defaultValue={0}
                style={{
                    width: "100%",
                }}
            />
        </FormItem>
    );
};

const InputText = ({ name, label, type = "text", required = true, disabled = false, placeholder = "" }) => {
    return (
        <FormItem label={label} name={name} required={required}>
            <Input disabled={disabled} type={type} placeholder={placeholder} />
        </FormItem>
    );
};

const InputPassword = ({ name, label, required = true, disabled = false, placeholder = "" }) => {
    return (
        <FormItem label={label} name={name} required={required}>
            <Input.Password
                disabled={disabled}
                placeholder={placeholder}
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
        </FormItem>
    );
};

const InputDesc = ({ name, label, required = true }) => {
    const [description, setDescription] = useState("");
    return (
        <FormItem label={label} name={name} required={required}>
            <ReactQuill
                style={{ height: "200px", fontSize: "16px" }}
                theme="snow"
                value={description}
                onChange={setDescription}
            />
        </FormItem>
    );
};

const InputImg = ({ name, label, required = true, handleChange, imageUrl, loading = false, maxCount = 1 }) => {
    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                {loading ? "Yuklanyapdi" : ""}
            </div>
        </div>
    );
    return (
        <FormItem label={label} name={name} required={required}>
            <Upload
                name={name}
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={({ file, onSuccess }) => {
                    setTimeout(() => {
                        onSuccess("ok");
                    }, 0);
                }}
                maxCount={maxCount}
                beforeUpload={beforeUpload}
                onChange={handleChange}
            >
                {imageUrl ? (
                    <img
                        src={imageUrl}
                        alt="avatar"
                        style={{
                            width: "100%",
                        }}
                    />
                ) : (
                    uploadButton
                )}
            </Upload>
        </FormItem>
    );
};

const InputDate = ({ name, label, required = true }) => {
    return (
        <FormItem label={label} name={name} required={required}>
            <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }} />
        </FormItem>
    );
};

const InputTime = ({ name, label, required = true }) => {
    return (
        <FormItem label={label} name={name} required={required}>
            <TimePicker format="HH:mm" style={{ width: "100%" }} />
        </FormItem>
    );
};

const InputSelect = ({ name, label, required = true, data = [], itemTitle = "title" }) => {
    return (
        <FormItem label={label} name={name} required={required}>
            <Select style={{ width: "100%" }} placeholder={label}>
                {data.length !== 0 &&
                    data.map((item, key) => {
                        return (
                            <Select.Option key={key} value={item.id}>
                                {item[itemTitle]}
                            </Select.Option>
                        );
                    })}
            </Select>
        </FormItem>
    );
};

const InputFile = ({
    name,
    label,
    required = true,
    maxCount = 1,
    type = "file",
    disabled = false,
    fileList = [],
    handleChange,
}) => {
    return (
        <FormItem label={label} name={name} required={required}>
            <Upload
                className="upload-file-css"
                name={name}
                customRequest={({ file, onSuccess }) => {
                    setTimeout(() => {
                        onSuccess("ok");
                    }, 0);
                }}
                maxCount={maxCount}
                beforeUpload={(e) => {
                    return type === "file" ? beforeUploadFile(e) : type === "audio" && beforeUploadAudio(e);
                }}
                accept={type === "file" ? "*" : type === "audio" && ".ogg, .wav, .mp3"}
                disabled={disabled}
                onChange={handleChange}
                fileList={fileList}
            >
                <Button
                    style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    icon={<UploadOutlined />}
                    disabled={disabled}
                >
                    Tanlash
                </Button>
            </Upload>
        </FormItem>
    );
};

export { InputText, InputDesc, InputImg, InputTime, InputDate, InputSelect, InputFile, InputPassword, InputNum };
