import { Modal } from "antd";

export default function DescriptionModal({ desc, title, open, onCancel, onOk }) {
    return (
        <Modal title={title} footer={false} open={open} onOk={onOk} onCancel={onCancel}>
            <div>
                <b style={{ fontSize: "18px" }}>Qisqacha ma'lumot:</b>
                <p style={{ fontSize: "16px" }} dangerouslySetInnerHTML={{ __html: desc }}></p>
            </div>
        </Modal>
    );
}
