import { Button, Col, Form, Row, Table } from "antd";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { deleteDocs, getDocsCategory, getDocs, patchDocs, postDocs } from "../../host/Config";
import { MdDelete } from "react-icons/md";
import { TbEdit } from "react-icons/tb";
import { message } from "antd";
import { useStore } from "../../store/Store";
import { InputFile, InputText } from "../../utils/Inputs";
import { InputSelect } from "../../utils/Inputs";

export default function Docs() {
    const [form] = Form.useForm();
    const [data, setData] = useState(null);
    const [edit, setedit] = useState(null);
    const [categories, setCategories] = useState(null);
    const [changePage, setchangePage] = useState(false);
    const [cookie] = useCookies();
    const [disabledFile, setDisabledFile] = useState(false);
    const [disabledLink, setDisabledLink] = useState(false);
    const [defaultFile, setDefaultFile] = useState([]);

    const setLoader = useStore((state) => state.setLoader);

    const editData = (res) => {
        if (res.file) {
            let file = res.file;
            setDefaultFile([
                {
                    uid: 1,
                    status: "done",
                    url: file,
                    name: file.slice(file.lastIndexOf("/") + 1),
                },
            ]);
            res.file = {
                fileList: [
                    {
                        uid: 1,
                        status: "done",
                        url: file,
                        name: file.slice(file.lastIndexOf("/") + 1),
                    },
                ],
            };
            setDisabledLink(true);
        } else {
            setDisabledFile(true);
        }
        form.setFieldsValue(res);
        setchangePage(true);
        setedit(res.id);
    };

    const showModal = () => {
        setchangePage(true);
    };

    const columns = [
        {
            title: "#",
            key: "#",
            render: (text, res, index) => {
                return index + 1;
            },
        },
        {
            title: "Nom",
            key: "title",
            dataIndex: "title",
        },
        {
            title: "Hujjat",
            key: "file",
            dataIndex: "id",
            width: "232px",
            render: (id, res) => {
                return (
                    <div
                        style={{
                            maxWidth: "200px",
                        }}
                    >
                        {(res.link || res.file) && (
                            <a href={res.link ? res.link : res.file} target="_blank" rel="noreferrer" style={{ textDecoration: "none", textAlign: "center", display: "inline-block", width: "100%" }}>
                                {(res.link || res.file) && "Havolaga o'tish"}
                            </a>
                        )}
                    </div>
                );
            },
        },
        {
            title: "Tur",
            key: "category_name",
            dataIndex: "category_name",
        },
        {
            title: "O'zgartirish",
            key: "edit",
            dataIndex: "id",
            render: (res, objc) => {
                return (
                    <Button
                        onClick={() => {
                            editData(objc);
                        }}
                        type="primary"
                    >
                        <TbEdit size={"1.3em"} />
                    </Button>
                );
            },
        },
        {
            title: "O'chirish",
            key: "delete",
            dataIndex: "id",
            render: (res) => {
                return (
                    <Button
                        type="primary"
                        onClick={() => {
                            deleteData(res);
                        }}
                        danger
                    >
                        <MdDelete size={"1.3em"} />
                    </Button>
                );
            },
        },
    ];

    const getData = (value) => {
        getDocs(cookie.token).then((res) => {
            let data = res.data;
            for (let i = 0; i < data.length; i++) {
                let val = value.filter((item) => item.id === data[i].category);
                data[i].category_name = val.length !== 0 ? val[0].title : "";
            }
            setData(data);
            setLoader(false);
        });
    };

    const deleteData = (id) => {
        setLoader(true);
        deleteDocs(cookie.token, id)
            .then((res) => {
                message.success("Ma'lumot o'chirildi");
                getData(categories);
            })
            .catch((err) => {
                setLoader(false);
                message.error("Ma'lumot o'chirilmadi");
            });
    };

    useEffect(() => {
        getDocsCategory(cookie.token).then((res) => {
            setCategories(res.data);
            getData(res.data);
        });
    }, []);

    const onFinish = (event) => {
        setLoader(true);
        let formData = new FormData();
        formData.append("title", event.title);
        if (event.link && !disabledLink) {
            formData.append("link", event.link);
        } else {
            formData.append("link", "");
        }
        formData.append("category", event.category);
        if (event.file && event.file.fileList && event.file.fileList !== 0 && !disabledFile) {
            if (event.file.fileList[0].originFileObj) {
                formData.append("file", event.file.fileList[0].originFileObj);
            }
        } else {
            formData.append("file", "");
        }
        if (edit === null) {
            postDocs(cookie.token, formData)
                .then((res) => {
                    message.success("Ma'lumot saqlandi");
                    handleClose();
                })
                .catch((err) => {
                    setLoader(false);
                    message.error("Ma'lumot saqlanmadi");
                });
        } else {
            patchDocs(cookie.token, formData, edit)
                .then((res) => {
                    message.success("Ma'lumot o'zgartirildi");
                    handleClose();
                })
                .catch((err) => {
                    setLoader(false);
                    message.error("Ma'lumot o'zgartirilmadi");
                });
        }
    };

    const handleClose = () => {
        setchangePage(false);
        setedit(null);
        setDisabledFile(false);
        setDisabledLink(false);
        setDefaultFile([]);
        getData(categories);
        form.resetFields();
    };

    const handleChange = (info) => {
        if (info.file.status === "done") {
            setDefaultFile(info.fileList);
            setDisabledLink(true);
        } else {
            setDefaultFile(info.fileList);
            setDisabledLink(false);
        }
    };

    return (
        <div>
            <div className="adminBox">
                {!changePage ? (
                    <>
                        <div className="admin_btns">
                            <Button style={{ marginBottom: "20px" }} onClick={showModal} type="primary">
                                Hujjat qo'shish
                            </Button>
                        </div>
                        <Table rowKey={(res) => res.id} dataSource={data} columns={columns} />
                    </>
                ) : (
                    <>
                        <Form
                            form={form}
                            name="basic"
                            labelCol={{
                                span: 24,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            onFieldsChange={(e, allE) => {
                                if (allE[2].value && allE[2].value.fileList.length !== 0) {
                                    setDisabledLink(true);
                                } else if (allE[3].value && allE[3].value.length !== 0) {
                                    setDisabledFile(true);
                                    form.setFieldValue("file");
                                } else {
                                    setDisabledLink(false);
                                    setDisabledFile(false);
                                }
                            }}
                            autoComplete="off"
                        >
                            <div className="admin_btns">
                                <Button style={{ marginBottom: "20px" }} onClick={handleClose} type="primary">
                                    Orqaga
                                </Button>
                                <Button style={{ marginLeft: "20px", backgroundColor: "#00a900" }} type="primary" htmlType="submit">
                                    Saqlash
                                </Button>
                            </div>
                            <Row style={{ paddingTop: "20px" }}>
                                <Col style={{ padding: "0px 20px" }} lg={12} md={16} sm={24} xs={24}>
                                    <InputText label="Nom" name="title" />
                                </Col>
                                <Col style={{ padding: "0px 20px" }} lg={6} md={8} sm={12} xs={24}>
                                    <InputSelect name="category" label="Tur" data={categories} />
                                </Col>
                                <Col style={{ padding: "0px 20px" }} lg={6} md={6} sm={12} xs={24}>
                                    <InputFile label="Hujjat" name="file" type="file" required={!disabledFile} disabled={disabledFile} fileList={defaultFile} handleChange={handleChange} />
                                </Col>
                                <Col style={{ padding: "0px 20px" }} lg={24} md={18} sm={24} xs={24}>
                                    <InputText label="Havola" name="link" required={!disabledLink} disabled={disabledLink} />
                                </Col>
                                <p className="text-danger text-center w-100" style={{ fontStyle: "italic" }}>
                                    Hujjat yoki havolani to'ldirish majburiy!
                                </p>
                            </Row>
                        </Form>
                    </>
                )}
            </div>
        </div>
    );
}
